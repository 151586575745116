import React, { Component } from 'react';
import { withTranslation, Translation, } from 'react-i18next';

import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';

import { View, ActivityIndicator} from "react-native-web";

/*import HeaderPC from '../../header/HeaderPC';
import HeaderMobile from '../../header/HeaderMobile';
import HeaderTablet from '../../header/HeaderTablet';*/

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

export default function LoadingPage() {




    return (

	         <div>


                  {!isMobileOnly && !isTablet &&
                    <BrowserView>

                      <View style={{width:viewportWidth, height: viewportHeight, position: 'relative', justifyContent: 'center', alignSelf: 'center', backgroundColor: '#09196b'}}>
                        <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '10px', backgroundColor: '#cda639'}}/>
                        <div style={{position: 'absolute', top: '10px', left: 0, width: '100%', height: '20px', backgroundColor: '#b8202d'}}/>
                        <div style={{position: 'absolute', top: '30px', left: 0, width: '100%', height: '10px', backgroundColor: '#cda639'}}/>



                         <div style={{fontFamily: 'Mohave',fontSize: '50px', color: '#fff', textAlign: 'center', textShadow: '1px 1px 2px black'}}>
                            RDC <span style={{color: '#5ceaf3'}}>{`//`}</span> LE RACHAT
                         </div>

                         <div style={{clear: 'both', height: '40px'}}/>

                        <ActivityIndicator  animating={true} color="#f2f068" />
                      </View>
                   </BrowserView>
                 }



                 {isMobileOnly &&
           			  <MobileView>
                    <View style={{width:viewportWidth, height: viewportHeight, position: 'relative', justifyContent: 'center', alignSelf: 'center', backgroundColor: '#09196b'}}>

                    <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '10px', backgroundColor: '#cda639'}}/>
                    <div style={{position: 'absolute', top: '10px', left: 0, width: '100%', height: '20px', backgroundColor: '#b8202d'}}/>
                    <div style={{position: 'absolute', top: '30px', left: 0, width: '100%', height: '10px', backgroundColor: '#cda639'}}/>



                     <div style={{fontFamily: 'Mohave',fontSize: '30px', color: '#fff', textAlign: 'center', textShadow: '1px 1px 2px black'}}>
                        RDC <span style={{color: '#5ceaf3'}}>{`//`}</span> LE RACHAT
                     </div>

                     <div style={{clear: 'both', height: '40px'}}/>



                      <ActivityIndicator  animating={true} color="#f2f068" />
                    </View>
           			 </MobileView>
           			}

           			{isTablet &&
           			  <TabletView>
                      <View style={{width:viewportWidth, height: viewportHeight, position: 'relative', justifyContent: 'center', alignSelf: 'center', backgroundColor: '#09196b'}}>

                      <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '10px', backgroundColor: '#cda639'}}/>
                      <div style={{position: 'absolute', top: '10px', left: 0, width: '100%', height: '20px', backgroundColor: '#b8202d'}}/>
                      <div style={{position: 'absolute', top: '30px', left: 0, width: '100%', height: '10px', backgroundColor: '#cda639'}}/>



                       <div style={{fontFamily: 'Mohave',fontSize: '50px', color: '#fff', textAlign: 'center', textShadow: '1px 1px 2px black'}}>
                          RDC <span style={{color: '#5ceaf3'}}>{`//`}</span> LE RACHAT
                       </div>

                       <div style={{clear: 'both', height: '40px'}}/>



                        <ActivityIndicator  animating={true} color="#f2f068" />



                      </View>
           			  </TabletView>
           			}








	        </div>

    )

}
